<template>
  <div>
    <load-profile></load-profile>
    <!-- Search Container Card -->
    <n-search-container
      :active="true"
      :show-header="false"
      @search="search"
      @reset="reset"
      v-if="searchFields.length"
    >
      <n-search-input ref="search" :fields="searchFields" v-model="params">
        <template #period="item">
          <b-form-group
            :label-for="item.field.key"
            :label="$t(item.field.label)"
          >
            <b-form-radio-group
              :ref="item.field.key"
              v-if="item.field.type === 'radio'"
              :id="item.field.key"
              v-model="params[item.field.key]"
              buttons
              button-variant="outline-primary rounded-0"
              class="flex-wrap"
            >
              <b-form-radio
                :value="option.value"
                v-for="option in item.field.options"
                :key="option.value"
                >{{ $t(option.text) }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </template>
      </n-search-input>
    </n-search-container>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-0-5">
        <b-row>
          <b-col>
            <label>{{ $t("general.entries") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
          </b-col>
        </b-row>
      </div>
      <div>
        <n-table
          @sort-changed="sortChanged"
          :fields="fields"
          :items="items"
          :busy="loading"
          :current-page="params.page"
          :per-page="$store.state.pagination.perPage"
          :total="total"
        >
          <template #cell(result)="data">
            <span :class="`text-${data.item.result} text-capitalize`">
              {{ data.item.result }}
            </span>
          </template>
          <template #cell(resultDigit)="data">
            <span :class="`text-${data.item.resultDigit} text-capitalize`">
              {{ data.item.resultDigit }}
            </span>
          </template>
          <template #cell(revenue)="data">
            <span
              :class="{
                'text-positive': data.item.revenue >= 0,
                'text-danger': data.item.revenue < 0,
              }"
            >
              {{ data.item.revenue | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(totalSteakUSD)="data">
            <span
              :class="{
                'text-positive': data.item.totalSteakUSD >= 0,
                'text-danger': data.item.totalSteakUSD < 0,
              }"
            >
              {{ data.item.totalSteakUSD | currency(840) }}
            </span>
          </template>
          <template #cell(totalSteakKHR)="data">
            <span
              :class="{
                'text-positive': data.item.totalSteakKHR >= 0,
                'text-danger': data.item.totalSteakKHR < 0,
              }"
            >
              {{ data.item.totalSteakKHR | currency(116) }}
            </span>
          </template>
          <template #cell(totalSteak)="data">
            <span
              :class="{
                'text-positive': data.item.totalSteak >= 0,
                'text-danger': data.item.totalSteak < 0,
              }"
            >
              {{ data.item.totalSteak | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(overUnderOutstanding)="data">
            <span class="text-under">
              {{ data.item.outstanding.under | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-over">
              {{ data.item.outstanding.over | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(rangeOutstanding)="data">
            <span class="text-1">
              {{ data.item.outstanding["1"] | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-2">
              {{ data.item.outstanding["2"] | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-3">
              {{ data.item.outstanding["3"] | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-4">
              {{ data.item.outstanding["4"] | currency(data.item.ccy) }}
            </span>
            |
            <span class="text-5">
              {{ data.item.outstanding["5"] | currency(data.item.ccy) }}
            </span>
          </template>
          <template #cell(createdAt)="data">
            {{ data.item.createdAt | formatDate }}
          </template>
          <template #custom-foot>
            <b-tr v-if="!loading && totalReport.outstanding">
              <b-th colspan="7"></b-th>
              <b-th class="text-right">
                <span
                  :class="{
                    'text-positive': totalReport.revenue >= 0,
                    'text-danger': totalReport.revenue < 0,
                  }"
                >
                  {{ totalReport.revenue | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <!-- <b-th class="text-right">
                <span
                  :class="{
                    'text-positive': totalReport.totalSteakUSD >= 0,
                    'text-danger': totalReport.totalSteakUSD < 0,
                  }"
                >
                  {{ totalReport.totalSteakUSD | currency(840) }}
                </span>
              </b-th>
              <b-th class="text-right">
                <span
                  :class="{
                    'text-positive': totalReport.totalSteakKHR >= 0,
                    'text-danger': totalReport.totalSteakKHR < 0,
                  }"
                >
                  {{ totalReport.totalSteakKHR | currency(116) }}
                </span>
              </b-th> -->
              <b-th class="text-right">
                <span
                  :class="{
                    'text-positive': totalReport.totalSteak >= 0,
                    'text-danger': totalReport.totalSteak < 0,
                  }"
                >
                  {{ totalReport.totalSteak | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th>
                <span class="text-under">
                  {{
                    totalReport.outstanding.under | currency(totalReport.ccy)
                  }}
                </span>
                |
                <span class="text-over">
                  {{ totalReport.outstanding.over | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th>
                <span class="text-1">
                  {{ totalReport.outstanding["1"] | currency(totalReport.ccy) }}
                </span>
                |
                <span class="text-2">
                  {{ totalReport.outstanding["2"] | currency(totalReport.ccy) }}
                </span>
                |
                <span class="text-3">
                  {{ totalReport.outstanding["3"] | currency(totalReport.ccy) }}
                </span>
                |
                <span class="text-4">
                  {{ totalReport.outstanding["4"] | currency(totalReport.ccy) }}
                </span>
                |
                <span class="text-5">
                  {{ totalReport.outstanding["5"] | currency(totalReport.ccy) }}
                </span>
              </b-th>
              <b-th></b-th>
            </b-tr>
          </template>
        </n-table>
        <n-pagination
          class="mt-2"
          :total="total"
          :per-page="$store.state.pagination.perPage"
          :page="params.page"
          @change="list"
        ></n-pagination>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BFormGroup,
  BInputGroup,
  BAvatar,
  BBadge,
  BFormRadioGroup,
  BFormRadio,
  BTr,
  BTh,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Repository from "@/repositories/RepositoryFactory";
import NPagination from "@/components/NPagination";
import NSearchContainer from "@/components/NSearchContainer";
import NSearchInput from "@/components/NSearchInput";
import NTable from "@/components/NTable";
import tableFields from "./tableField";
import searchInputs from "./searchInput";
import { avatarText } from "@core/utils/filter";
import { calculateDatesByPeriod } from "@/libs/helper.js";
import moment from "moment";

const ResultRepository = Repository.get("resultReport");

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BAvatar,
    NPagination,
    NSearchContainer,
    NSearchInput,
    NTable,
    vSelect,
    BFormGroup,
    BInputGroup,
    BBadge,
    BFormRadioGroup,
    BFormRadio,
    BTr,
    BTh,
  },
  watch: {
    perPage(value) {
      this.list(1);
    },
    "params.period": function (value) {
      const dates = calculateDatesByPeriod(value);
      if (dates.length) {
        this.params.startDate = dates[0];
        this.params.toDate = dates[1];
      }
    },
  },
  computed: {
    perPage: {
      get() {
        return this.$store.state.pagination.perPage;
      },
      set(value) {
        this.$store.commit("pagination/SET_PER_PAGE", value);
      },
    },
    activeSearch() {
      return (
        !!this.$route.query.userId ||
        !!this.$route.query.startDate ||
        !!this.$route.query.toDate
      );
    },
    typeId: function () {
      if (this.$store.state.profile.typeId) {
        return this.$store.state.profile.typeId;
      }
      return this.userData?.id;
    },
  },

  data() {
    return {
      items: [],
      loading: false,
      total: 0,
      perPageOptions: this.$store.state.pagination.perPageOptions,
      params: {
        page: Number(this.$route.query.page) || 1,
        order: this.$route.query.order || "id",
        sort: this.$route.query.sort || null,
        userId: Number(this.$route.query.userId) || "",
        typeId: Number(this.$route.query.typeId) || "",
        startDate: this.$route.query.startDate || moment().format("YYYY-MM-DD"),
        toDate: this.$route.query.toDate || moment().format("YYYY-MM-DD"),
        period: this.$route.query.period || "today",
        search: this.$route.query.search || "",
      },
      avatarText,
      totalReport: {},
    };
  },
  mounted() {
    this.getData(this.params.page);
  },
  methods: {
    updateQuerySting() {
      this.$router.replace({ query: this.params });
    },
    list(page = 1) {
      this.params.page = page;
      this.updateQuerySting();
      this.getData();
    },
    sortChanged(sortBy, sortDirection) {
      this.params.order = sortBy;
      this.params.sort = sortDirection;
      this.updateQuerySting();
      this.getData();
    },
    search(searchText) {
      this.params.search = searchText;
      this.updateQuerySting();
      this.getData();
    },
    reset() {
      this.$refs.search.reset();
      this.updateQuerySting();
      this.getData();
    },
    getData() {
      this.loading = true;
      ResultRepository.index({
        ...this.params,
      })
        .then((response) => {
          let data = response.data.data.list;
          this.items = [...data];
          this.total = response.data.data.total;
          this.loading = false;
          this.totalReport = {};
          this.getTotal();
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getTotal() {
      ResultRepository.total({
        ...this.params,
      }).then((response) => {
        let data = response.data.data;
        this.totalReport = {
          ...data,
        };
      });
    },
  },
  setup() {
    const fields = [...tableFields];
    const searchFields = [...searchInputs];
    return {
      fields,
      searchFields,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
